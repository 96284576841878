import React from "react"

import PropTypes from "prop-types"

import ProductList from "../components/products/ProductList"
import SEO from "../components/seo"
import { searchProducts } from "../graphql"

const SearchPage = ({ location }) => {
  const [search, setSearch] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [products, setProducts] = React.useState({})

  React.useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search)
      const search = queryParams.get("query")
      setSearch(search)
      setIsLoading(true)
      searchProducts(50, search).then((res) => {
        setIsLoading(false)
        setProducts(res.products)
      })
    }
  }, [location.search])

  return (
    <>
      <SEO title="Search Products" path="/products" />
      <ProductList
        hasHeader={false}
        isLoading={isLoading}
        title={`Results for ${search}`}
        products={products}
      />
    </>
  )
}

SearchPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default SearchPage
